<template>
  <div
    class="missionDays__item"
    :class="{ missionDays__item__active: isActive }"
  >
    <div>
      <span class="missionDays__item-username">{{ name }}</span>
    </div>
  </div>
</template>

<script>
import config from "../config";

export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    iconSrc: {
      type: String,
      default: "",
    },
    bgColor: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
    selected: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    resolveImage(src) {
      return config.HOST + src;
    },
    resolveCategoryBg(bgColor) {
      return "background-color: " + bgColor;
    },
    resolveColor(color) {
      return "color: " + color;
    },
  },
};
</script>

<style lang="scss" scoped>
.missionDays__item {
  user-select: none;
  margin-right: 10px;
  border: 1px solid var(--main);
  border-radius: 100px;

  &__active {
    background: var(--main);
    border: 1px solid var(--main);

    .missionDays__item-username {
      color: white !important;
    }
  }
}

.missionDays__item-username {
  color: var(--main);
  width: 150px;
}
</style>
